import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  json,
  MetaFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { DefaultLayout } from "./layouts/default";
import tailwind from "./tailwind.css?url";
import i18next from "./i18next.server";
import { useLocalization } from "./hooks/localize";
import { commitSession, getSession } from "./utils/sessions.server";
import { Logo } from "./components/Logo";
import { GlobalLoading } from "./components/GlobalLoading";
import { Toaster, toast as sonnerToast } from 'sonner'
import { useEffect } from "react";
import { createId } from "@paralleldrive/cuid2";

import logo_en from "~/assets/napaLogo_horizontal_darkBackground_en.svg";
export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwind },
];
export const meta: MetaFunction = () => {
  return [{ title: "NAPA Rebates" }];
};

export function shouldRevalidate({ actionResult, defaultShouldRevalidate }: { actionResult: any; defaultShouldRevalidate: boolean }) {
  if (actionResult?.noRevalidate) return false
  return defaultShouldRevalidate
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  let locale = await i18next.getLocale(request);
  const session = await getSession(request.headers.get("Cookie"));

  const toast = session.get('globalMessage') || null

  return json({ locale, user: session.data, toast, loaderId: createId() }, {
    headers: {
      'Set-Cookie': await commitSession(session)
    }
  });
};

function App() {
  let { locale, toast, loaderId } = useLoaderData<typeof loader>();

  const location = useLocation();
  const isDefaultLayout = ["/temp/"].some((url) =>
    location.pathname.startsWith(url) ? true : false
  );

  const { changeLanguage } = useLocalization();
  changeLanguage(locale);

  useEffect(() => {
    if (toast) {
      sonnerToast(toast)
    }
  }, [toast, loaderId])

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="google" content="notranslate" />
        <Meta />
        <Links />
      </head>
      <body
        className={`
          ${isDefaultLayout
            ? "bg-primary text-white/90"
            : "bg-white text-black/90"
          } h-dvh overflow-y-scroll`}
      >
        {/* <link rel="stylesheet" type="text/css" href="https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.50.min.css?key=ek33-pf53-km28-nx89" /><script type="text/javascript" src="https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.50.min.js?key=ek33-pf53-km28-nx89"></script> */}
        {isDefaultLayout ? (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ) : (
          <Outlet />
        )}
        <div id="portal" style={{ position: "relative", zIndex: 1000 }} />
        <GlobalLoading />
        <Toaster theme='dark' offset='64px' />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);
export function ErrorBoundary() {
  const error = useRouteError();
  const { t } = useLocalization();
  const ERROR_MESSAGES = {
    UNAUTHORIZED: t("UNAUTHORIZED"),
  };
  captureRemixErrorBoundaryError(error);
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body className="bg-primary text-white/90 flex items-center justify-center h-screen">
        <div className="text-center flex flex-col gap-8">
          <div className="w-80">
            <img src={logo_en} />
          </div>
          {error.data && ERROR_MESSAGES[error.data] || error.data || error.message}
          <div>
            <Link to="/" className="underline">
              Continue
            </Link>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
